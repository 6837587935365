body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400&display=swap');

.App {
    font-family: 'Montserrat', sans-serif;
    min-height: 100vh;
    position:relative;
  }

.font-title{
    font-family: 'Josefin Sans', sans-serif;

}
.indented{
    text-indent: 8%;
}
.footer-link-contact{
    fill: white;
}
.navbar-logo-brand{
    fill: rgb(41, 141, 255); /* rgb(78, 104, 255); */
}
.bg-muted{
    background-color: rgb(158, 158, 158);
}

.navbar small{
    font-size:1.2rem;
    position:relative;
    bottom: 0.4rem;
}
.animated-opacity{
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -ms-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
}
.animated-background-color{
    -webkit-transition: background-color 0.4s ease-in-out;
    -moz-transition: background-color 0.4s ease-in-out;
    -ms-transition: background-color 0.4s ease-in-out;
    -o-transition: background-color 0.4s ease-in-out;
    transition: background-color 0.4s ease-in-out;
}
.animated-all{
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}
.animated-all-quick{
    -webkit-transition: all 0.15s ease-in-out;
    -moz-transition: all 0.15s ease-in-out;
    -ms-transition: all 0.15s ease-in-out;
    -o-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
}
.button-highlights-fullscreen{
    color: rgba(255, 255, 255);
    text-shadow: 1px 0px 5px rgba(0, 0, 0, 0.7);
    font-size: 350%;
    /* 20 Ruby */
}
.button-highlights-fullscreen:hover{
    color: rgb(176, 188, 255);
}
.button-highlights-fullscreen:active{
    color: rgb(148, 148, 148);
}
.button-highlights-fullscreen:disabled{
    opacity: 0;
}
.button-highlights{
    background-color: rgba(255, 255, 255, 0.12);
}
.button-highlights:hover{
    background-color: rgba(255, 255, 255, 0.4);
}
.button-highlights:active{
    background-color: grey;
}
.button-highlights:disabled{
    opacity: 0;
}

.anim-hover-lighten-light:hover{
    filter: brightness(130%)
}
.anim-hover-lighten:hover{
    filter: brightness(170%)
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.link-no-underline {
    text-decoration: none; 
}
.flex-fill-fixed{
    flex-grow: 1;
    flex-shrink: 1;
    flex: 1;
}

/* Landing Page */
#landing_services{
    position: absolute;
    z-index:-1;
}
#about1, #landingProjectsSection, #about4{
    position:relative;
}
#about4 .behind{
    position:absolute;
}
#testimonial-portrait-holder{
    width: 70px;
    height: 70px;
    overflow: hidden;
}
#testimonial-portrait-holder img{
    width: inherit;
    height: inherit;
    object-fit: cover;
}
.portfolio-pic{
    width:150px;
    height:150px;
}

#landingProjectsSection div.card:hover {
    background-color: rgb(168, 204, 255);
}

@media only screen and (min-width: 1200px){
    .portfolio-pic{
        width:200px;
        height:200px;
    }
}

@import url("scss_custom.css");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css");